<template>
  <div class="emlo-diamond" :style="{direction: 'ltr'}">
    <svg class="emlo-diamond__svg" v-bind="{ viewBox }">
      <rect x="0" y="0" :width="W" :height="H" fill="transparent" />

      <!-- eslint-disable-next-line vue/valid-v-for -->
      <template v-for="p in polyPath">
        <!-- eslint-disable-next-line -->
        <path v-bind="p"  />
      </template>

      <template v-for="s in sectors">
        <template v-for="sp in s">
          <!-- eslint-disable-next-line vue/valid-v-for -->
          <component :is="sp.type" v-bind="sp" class="emlo-diamond__svg-text">
            {{ sp.text }}
          </component>
        </template>
      </template>
    </svg>
  </div>
</template>

<script setup>
import { computed, watch, shallowRef } from 'vue'

const W = 700
const H = 460
const SECTORCOUNT = 8

const MIN_FONT_SIZE = 22
const MAX_FONT_SIZE = 32
const RAY_SCALE_BORDER = 70

const TEXT_COLOR = '#fff'
const DIAMOND_STROKE = 'rgba(255, 255, 255, 0.5)'
const DIAMOND_BG_COLORS = [
  '185',
  '157',
  '134',
  '114',
  '97',
  '82',
  '71',
  '60',
  '51',
  '43',
]
// const ORANGE_1 = '#e95711'
// const ORANGE_2 = '#ff8528'

const props = defineProps({
  max: {
    type: String,
    default: '100', // number in string String(number), not NaN
  },
  values: {
    type: String,
    default: '', // {text: string, value: number} []
  },
  colors: {
    type: String,
    default: '["#e95711","#ff8528"]', // {first: string, second: string}
  },
  height: {
    type: String,
    default: '460', // number in string String(number), not NaN
  },
  width: {
    type: String,
    default: '700', // number in string String(number), not NaN
  },
  overallCognitiveActivity: { // overallCognitiveActivity (0-10, 0 - nothing)
    type: Number,
    default: 0
  }
})

const viewBox = computed(() => `0 0 ${props.width} ${props.height}`)

const currentValues = shallowRef([])

const polys = computed(() => {
  const r0 = (H * 0.9) / 2

  return [
    { r: r0 * 0.92, color: DIAMOND_BG_COLORS[0] },
    { r: r0 * 0.85, color: DIAMOND_BG_COLORS[1] },
    { r: r0 * 0.78, color: DIAMOND_BG_COLORS[2] },
    { r: r0 * 0.71, color: DIAMOND_BG_COLORS[3] },
    { r: r0 * 0.64, color: DIAMOND_BG_COLORS[4] },
    { r: r0 * 0.57, color: DIAMOND_BG_COLORS[5] },
    { r: r0 * 0.5, color: DIAMOND_BG_COLORS[6] },
    { r: r0 * 0.43, color: DIAMOND_BG_COLORS[7] },
    { r: r0 * 0.36, color: DIAMOND_BG_COLORS[8] },
    { r: r0 * 0.29, color: DIAMOND_BG_COLORS[9] },
  ]
})

const polyPath = computed(() => {
  // eslint-disable-next-line no-unused-vars

  //let ocrLevelValue = (props.overallCognitiveActivity - 1000) / (polys.value.length * 10) + 1;

  let ocrLevelValue = (props.overallCognitiveActivity - 1100) / 6;
  let ocrLevel = 0;

  if (ocrLevelValue > 100) {
    ocrLevel = 10;
  } else if (ocrLevelValue < 0) {
    ocrLevel = 0;
  } else {
    ocrLevel = ocrLevelValue / 10 + 1;
  }

  //console.log("OCR = " + props.overallCognitiveActivity, "OCR Level Value = " + ocrLevelValue, "OCR Level = " + ocrLevel);

  return polys.value.map(({ r, color }, idx) => ({
    d: poly(W, H, r, SECTORCOUNT),
    stroke: idx !== 0 ? DIAMOND_STROKE : '',
    //stroke:  DIAMOND_STROKE,
    fill: idx > polys.value.length - ocrLevel ? '#FF0000' : '#000',
    'fill-opacity': idx !== 0 ? '0.15' : 0,
  }))
})

const sectors = computed(() => {
  return currentValues.value
    .slice(0, SECTORCOUNT)
    .map((v, idx) =>
      sector(W, H, SECTORCOUNT, idx, polys.value[1].r, +props.max, v),
    )
})

let stopAnim = null

watch(
  () => props.values,
  (v) => {
    if (!v) return

    const propsValues = JSON.parse(v)

    stopAnim && stopAnim()

    // eslint-disable-next-line no-unused-vars
    stopAnim = animLoop((delta) => {
      if (
        propsValues.length > 0 &&
        propsValues.every(
          (vv, j) =>
            Math.abs(vv.value - (currentValues.value[j]?.value || 0)) < 0.001,
        )
      ) {
        return false
      }
      if (propsValues.length > 0) {
        currentValues.value = propsValues.map((vv, i) => ({
          ...vv,
          value: lerp(currentValues.value[i]?.value || 0, vv.value, 0.1),
        }))
      }
    }, 200)
  },
  {
    immediate: true,
  },
)

function sector(w, h, nSectors, sectorIndex, r, maxValue, { value, text }) {
  const c = [w, h].map((i) => i / 2)
  const angleOffset = (Math.PI * 2) / nSectors
  value = Math.min(Math.max(value, 0), maxValue)
  const r1 = (r * value) / maxValue

  const a0 = angleOffset * sectorIndex
  const a1 = a0 + angleOffset
  const a2 = a0 - angleOffset

  const pt = [r * Math.cos(a0), r * Math.sin(a0)]
  const p0 = [r1 * Math.cos(a0), r1 * Math.sin(a0)]
  const p1 = [r1 * Math.cos(a1), r1 * Math.sin(a1)]
  const p2 = [r1 * Math.cos(a2), r1 * Math.sin(a2)]

  const p4 = p1.map((_, i) => p1[i] - p0[i])
  const p5 = p2.map((_, i) => p2[i] - p0[i])

  const colors = JSON.parse(props.colors)
  const points = [
    [c, p0, p4.map((i) => i / 2.0)],
    [c, p0, p5.map((i) => i / 2.0)],
  ]
  const textConfig = [
    {
      a: 'start',
      x: 0,
      y: 0,
    },
    {
      a: 'start',
      x: 0,
      y: 0,
    },
    {
      a: 'middle',
      x: 0,
      y: 0,
    },
    {
      a: 'end',
      x: 0,
      y: 0,
    },
    {
      a: 'end',
      x: 0,
      y: 0,
    },
    {
      a: 'end',
      x: 0,
      y: 0,
    },
    {
      a: 'middle',
      x: 0,
      y: 0,
    },
    {
      a: 'start',
      x: 0,
      y: 0,
    },
  ]

  const ti = textConfig[sectorIndex % textConfig.length]
  return [
    ...points.map((data, dataIndex) => {
      const d = data.map((p) => p.map((v) => v).join(',')).join('l')
      return {
        type: 'path',
        d: `M${d}Z`,
        fill: colors[dataIndex],
        stroke: 'none',
      }
    }),
    {
      type: 'text',
      'text-anchor': ti.a,
      'dominant-baseline': 'middle',
      text,
      fill: TEXT_COLOR,
      x: c[0] + pt[0] * 1.15 + ti.x,
      y: c[1] + pt[1] * 1.2 + ti.y,
      'font-size': value < RAY_SCALE_BORDER ? MIN_FONT_SIZE : MAX_FONT_SIZE,
    },
  ]
}

function poly(w, h, r, nSectors) {
  const c = [w, h].map((i) => i / 2)
  const angleOffset = (Math.PI * 2) / nSectors

  const points = new Array(nSectors)
    .join()
    .split(',')
    .map((i, j) => {
      const a = j * angleOffset
      return [Math.cos(a), Math.sin(a)].map((v, i) => v * r + c[i])
    })

  return 'M' + points.map((p) => p.join(',')).join('L') + 'Z'
}

function animLoop(render) {
  const ctx = { running: true }

  const stop = () => (ctx.running = false)

  let lastFrame = +new Date()

  function loop(now) {
    // stop the loop if render returned false
    if (ctx.running !== false) {
      requestAnimationFrame(loop)
      const deltaT = now - lastFrame
      // do not render frame when deltaT is too high
      if (deltaT < 160) {
        ctx.running = render(deltaT)
      }
      lastFrame = now
    }
  }

  loop(lastFrame)

  return stop
}

function lerp(x0, x1, k) {
  return x0 + (x1 - x0) * k
}
</script>
