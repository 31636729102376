import dayjs from "dayjs";
import {computed} from "vue";
//import {useOrganizationStore} from "Common/stores/organizationStore";

function formatDateTimeToLocal(date){
    const dayjsDate = dayjs(date || {});
    return dayjsDate.format(getLocalDateFormat() + " HH:mm");
}

function formatDate(date, format) {
    return dayjs(date || {}).format(format);
}

function formatTime(date, format) {
    return dayjs(date || {}).format("HH:mm");
}

function formatDateToLocal(date){
    return formatDate(date, getLocalDateFormat());
}

function formatMonthToLocal(date){
    const dayjsDate = dayjs(date || {});
    return dayjsDate.format(getLocalDateFormat('month'));
}

function formatDateMonthToLocal(date, format = null){
    let dayjsDate;
    if(format) dayjsDate = dayjs(date || {}, format);
    else dayjsDate = dayjs(date || {});
    return dayjsDate.format(getLocalDateFormat('date-month'));
}

function useOrganizationDateFormat(useLocal = false) {
    //const organizationStore = useOrganizationStore();
    //const org = computed(() => useLocal ? null : organizationStore.organization);
    
    const dateFormat = computed(() => getLocalDateFormat());
    //const dateFormat = computed(() => org.value?.dateFormat || getLocalDateFormat());

    const monthFormat = computed(() => {
        if (!dateFormat.value)
            return getLocalDateFormat("month");
        return (dateFormat.value === "YYYY-MM-DD" ? "YYYY/MM" : "MM/YYYY");
    });

    const dateTimeFormat = computed(() => `${dateFormat.value} HH:mm`);

    const dayMonthFormat = computed(() => {
        if (!dateFormat.value)
            return getLocalDateFormat("date-month");
        return dateFormat.value === "YYYY-MM-DD" ? "MM/DD" : "DD/MM";
    });

    return {
        formatDate: d => formatDate(d, dateFormat.value),
        formatTime: d => formatTime(d, dateFormat.value),
        formatMonth: d => formatDate(d, monthFormat.value),
        formatDateTime: d => formatDate(d, dateTimeFormat.value),
        formatDayMonth: d => formatDate(d, dayMonthFormat.value),
        dateFormat,
        monthFormat,
    }
}

export {
    useOrganizationDateFormat,
    formatDateToLocal,
    formatMonthToLocal,
    formatDateTimeToLocal,
    formatDateMonthToLocal,
    dateFromNumber,
}

/**
 * formats date from numeric form (YYYYMMDD) to ISO form (YYYY-MM-DD)
 * @param v
 */
function dateFromNumber(v) {
    return String(v).replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');
}

function getLocalDateFormat(type = 'day'){
    switch (type){
        case "date-month": return "DD/MM";
        case "month": return "MM/YYYY";
        default: return "YYYY-MM-DD";
    }
    
    
    const systemLang = navigator.language.toLowerCase();
    if(dateFormatTypes.dmy.find((lang) => lang.toLowerCase() === systemLang)){
        switch (type){
            case "date-month": return "DD/MM";
            case "month": return "MM/YYYY";
            default: return "DD/MM/YYYY";
        }
    }
    if(dateFormatTypes.mdy.find((lang) => lang.toLowerCase() === systemLang)){
        switch (type){
            case "date-month": return "MM/DD";
            case "month": return "MM/YYYY";
            default: return "MM/DD/YYYY";
        }
    }
    if(dateFormatTypes.ymd.find((lang) => lang.toLowerCase() === systemLang)){
        switch (type){
            case "date-month": return "MM/DD";
            case "month": return "YYYY/MM";
            default: return "YYYY/MM/DD";
        }
    }
    return "DD/MM/YYYY";
}

const dateFormatTypes = {
    "dmy": ["am-ET", "ar-AE", "ar-BH", "ar-DZ", "ar-EG", "ar-IQ", "ar-JO", "ar-KW", "ar-LB", "ar-LY", "ar-MA", "ar-OM", "ar-QA", "ar-SA", "ar-SY", "ar-TN", "ar-YE", "arn-CL", "as-IN", "az-Cyrl-AZ", "az-Latn-AZ", "ba-RU", "be-BY", "bg-BG", "bn-BD", "bn-IN", "br-FR", "bs-Cyrl-BA", "bs-Latn-BA", "ca-ES", "co-FR", "cs-CZ", "cy-GB", "da-DK", "de-AT", "de-CH", "de-DE", "de-LI", "de-LU", "dsb-DE", "dv-MV", "el-GR", "en-AU", "en-BZ", "en-CA", "en-GB", "en-IE", "en-IN", "en-JM", "en-MY", "en-NZ", "en-SG", "en-TT", "es-AR", "es-BO", "es-CL", "es-CO", "es-CR", "es-DO", "es-EC", "es-ES", "es-GT", "es-HN", "es-MX", "es-NI", "es-PE", "es-PR", "es-PY", "es-SV", "es-UY", "es-VE", "et-EE", "fi-FI", "fo-FO", "fr-BE", "fr-CH", "fr-FR", "fr-LU", "fr-MC", "fy-NL", "ga-IE", "gd-GB", "gl-ES", "gsw-FR", "gu-IN", "ha-Latn-NG", "he-IL", "hi-IN", "hr-BA", "hr-HR", "hsb-DE", "hy-AM", "id-ID", "ig-NG", "is-IS", "it-CH", "it-IT", "iu-Cans-CA", "iu-Latn-CA", "ka-GE", "kk-KZ", "kl-GL", "kn-IN", "kok-IN", "ky-KG", "lb-LU", "lo-LA", "mi-NZ", "mk-MK", "ml-IN", "mr-IN", "ms-BN", "ms-MY", "mt-MT", "nb-NO", "nl-BE", "nl-NL", "nn-NO", "oc-FR", "or-IN", "pa-IN", "pl-PL", "prs-AF", "ps-AF", "pt-BR", "pt-PT", "qut-GT", "quz-BO", "quz-EC", "quz-PE", "rm-CH", "ro-RO", "ru-RU", "sa-IN", "se-FI", "se-NO", "sk-SK", "sl-SI", "sma-NO", "smj-NO", "smn-FI", "sms-FI", "sr-Cyrl-BA", "sr-Cyrl-CS", "sr-Cyrl-ME", "sr-Cyrl-RS", "sr-Latn-BA", "sr-Latn-CS", "sr-Latn-ME", "sr-Latn-RS", "sv-FI", "syr-SY", "ta-IN", "te-IN", "tg-Cyrl-TJ", "th-TH", "tk-TM", "tr-TR", "tt-RU", "tzm-Latn-DZ", "uk-UA", "ur-PK", "uz-Cyrl-UZ", "uz-Latn-UZ", "vi-VN", "wo-SN", "yo-NG", "zh-HK", "zh-MO", "zh-SG"],
    "mdy": ["en-029", "en-PH", "en-US", "en-ZW", "es-PA", "es-US", "fa-IR", "fil-PH", "moh-CA", "ne-NP", "rw-RW", "sah-RU", "sw-KE"],
    "ymd": ["af-ZA", "bo-CN", "en-ZA", "eu-ES", "fr-CA", "hu-HU", "ii-CN", "ja-JP", "km-KH", "ko-KR", "lt-LT", "lv-LV", "mn-Mong-CN", "nso-ZA", "se-SE", "si-LK", "sma-SE", "smj-SE", "sq-AL", "sv-SE", "tn-ZA", "ug-CN", "xh-ZA", "zh-CN", "zh-TW", "zu-ZA"],
}

const dateFormats = {
    "af-ZA": "YYYY/MM/DD",
    "am-ET": "D/M/YYYY",
    "ar-AE": "DD/MM/YYYY",
    "ar-BH": "DD/MM/YYYY",
    "ar-DZ": "DD-MM-YYYY",
    "ar-EG": "DD/MM/YYYY",
    "ar-IQ": "DD/MM/YYYY",
    "ar-JO": "DD/MM/YYYY",
    "ar-KW": "DD/MM/YYYY",
    "ar-LB": "DD/MM/YYYY",
    "ar-LY": "DD/MM/YYYY",
    "ar-MA": "DD-MM-YYYY",
    "ar-OM": "DD/MM/YYYY",
    "ar-QA": "DD/MM/YYYY",
    "ar-SA": "DD/MM/yy",
    "ar-SY": "DD/MM/YYYY",
    "ar-TN": "DD-MM-YYYY",
    "ar-YE": "DD/MM/YYYY",
    "arn-CL": "DD-MM-YYYY",
    "as-IN": "DD-MM-YYYY",
    "az-Cyrl-AZ": "DD.MM.YYYY",
    "az-Latn-AZ": "DD.MM.YYYY",
    "ba-RU": "DD.MM.yy",
    "be-BY": "DD.MM.YYYY",
    "bg-BG": "DD.M.YYYY",
    "bn-BD": "DD-MM-yy",
    "bn-IN": "DD-MM-yy",
    "bo-CN": "YYYY/M/D",
    "br-FR": "DD/MM/YYYY",
    "bs-Cyrl-BA": "D.M.YYYY",
    "bs-Latn-BA": "D.M.YYYY",
    "ca-ES": "DD/MM/YYYY",
    "co-FR": "DD/MM/YYYY",
    "cs-CZ": "D.M.YYYY",
    "cy-GB": "DD/MM/YYYY",
    "da-DK": "DD-MM-YYYY",
    "de-AT": "DD.MM.YYYY",
    "de-CH": "DD.MM.YYYY",
    "de-DE": "DD.MM.YYYY",
    "de-LI": "DD.MM.YYYY",
    "de-LU": "DD.MM.YYYY",
    "dsb-DE": "D. M. YYYY",
    "dv-MV": "DD/MM/yy",
    "el-GR": "D/M/YYYY",
    "en-029": "MM/DD/YYYY",
    "en-AU": "D/MM/YYYY",
    "en-BZ": "DD/MM/YYYY",
    "en-CA": "DD/MM/YYYY",
    "en-GB": "DD/MM/YYYY",
    "en-IE": "DD/MM/YYYY",
    "en-IN": "DD-MM-YYYY",
    "en-JM": "DD/MM/YYYY",
    "en-MY": "D/M/YYYY",
    "en-NZ": "D/MM/YYYY",
    "en-PH": "M/D/YYYY",
    "en-SG": "D/M/YYYY",
    "en-TT": "DD/MM/YYYY",
    "en-US": "M/D/YYYY",
    "en-ZA": "YYYY/MM/DD",
    "en-ZW": "M/D/YYYY",
    "es-AR": "DD/MM/YYYY",
    "es-BO": "DD/MM/YYYY",
    "es-CL": "DD-MM-YYYY",
    "es-CO": "DD/MM/YYYY",
    "es-CR": "DD/MM/YYYY",
    "es-DO": "DD/MM/YYYY",
    "es-EC": "DD/MM/YYYY",
    "es-ES": "DD/MM/YYYY",
    "es-GT": "DD/MM/YYYY",
    "es-HN": "DD/MM/YYYY",
    "es-MX": "DD/MM/YYYY",
    "es-NI": "DD/MM/YYYY",
    "es-PA": "MM/DD/YYYY",
    "es-PE": "DD/MM/YYYY",
    "es-PR": "DD/MM/YYYY",
    "es-PY": "DD/MM/YYYY",
    "es-SV": "DD/MM/YYYY",
    "es-US": "M/D/YYYY",
    "es-UY": "DD/MM/YYYY",
    "es-VE": "DD/MM/YYYY",
    "et-EE": "D.MM.YYYY",
    "eu-ES": "YYYY/MM/DD",
    "fa-IR": "MM/DD/YYYY",
    "fi-FI": "D.M.YYYY",
    "fil-PH": "M/D/YYYY",
    "fo-FO": "DD-MM-YYYY",
    "fr-BE": "D/MM/YYYY",
    "fr-CA": "YYYY-MM-DD",
    "fr-CH": "DD.MM.YYYY",
    "fr-FR": "DD/MM/YYYY",
    "fr-LU": "DD/MM/YYYY",
    "fr-MC": "DD/MM/YYYY",
    "fy-NL": "D-M-YYYY",
    "ga-IE": "DD/MM/YYYY",
    "gd-GB": "DD/MM/YYYY",
    "gl-ES": "DD/MM/yy",
    "gsw-FR": "DD/MM/YYYY",
    "gu-IN": "DD-MM-yy",
    "ha-Latn-NG": "D/M/YYYY",
    "he-IL": "DD/MM/YYYY",
    "hi-IN": "DD-MM-YYYY",
    "hr-BA": "D.M.YYYY.",
    "hr-HR": "D.M.YYYY",
    "hsb-DE": "D. M. YYYY",
    "hu-HU": "YYYY. MM. DD.",
    "hy-AM": "DD.MM.YYYY",
    "id-ID": "DD/MM/YYYY",
    "ig-NG": "D/M/YYYY",
    "ii-CN": "YYYY/M/D",
    "is-IS": "D.M.YYYY",
    "it-CH": "DD.MM.YYYY",
    "it-IT": "DD/MM/YYYY",
    "iu-Cans-CA": "D/M/YYYY",
    "iu-Latn-CA": "D/MM/YYYY",
    "ja-JP": "YYYY/MM/DD",
    "ka-GE": "DD.MM.YYYY",
    "kk-KZ": "DD.MM.YYYY",
    "kl-GL": "DD-MM-YYYY",
    "km-KH": "YYYY-MM-DD",
    "kn-IN": "DD-MM-yy",
    "ko-KR": "YYYY. MM. DD",
    "kok-IN": "DD-MM-YYYY",
    "ky-KG": "DD.MM.yy",
    "lb-LU": "DD/MM/YYYY",
    "lo-LA": "DD/MM/YYYY",
    "lt-LT": "YYYY.MM.DD",
    "lv-LV": "YYYY.MM.DD.",
    "mi-NZ": "DD/MM/YYYY",
    "mk-MK": "DD.MM.YYYY",
    "ml-IN": "DD-MM-yy",
    "mn-MN": "yy.MM.DD",
    "mn-Mong-CN": "YYYY/M/D",
    "moh-CA": "M/D/YYYY",
    "mr-IN": "DD-MM-YYYY",
    "ms-BN": "DD/MM/YYYY",
    "ms-MY": "DD/MM/YYYY",
    "mt-MT": "DD/MM/YYYY",
    "nb-NO": "DD.MM.YYYY",
    "ne-NP": "M/D/YYYY",
    "nl-BE": "D/MM/YYYY",
    "nl-NL": "D-M-YYYY",
    "nn-NO": "DD.MM.YYYY",
    "nso-ZA": "YYYY/MM/DD",
    "oc-FR": "DD/MM/YYYY",
    "or-IN": "DD-MM-yy",
    "pa-IN": "DD-MM-yy",
    "pl-PL": "DD.MM.YYYY",
    "prs-AF": "DD/MM/yy",
    "ps-AF": "DD/MM/yy",
    "pt-BR": "D/M/YYYY",
    "pt-PT": "DD-MM-YYYY",
    "qut-GT": "DD/MM/YYYY",
    "quz-BO": "DD/MM/YYYY",
    "quz-EC": "DD/MM/YYYY",
    "quz-PE": "DD/MM/YYYY",
    "rm-CH": "DD/MM/YYYY",
    "ro-RO": "DD.MM.YYYY",
    "ru-RU": "DD.MM.YYYY",
    "rw-RW": "M/D/YYYY",
    "sa-IN": "DD-MM-YYYY",
    "sah-RU": "MM.DD.YYYY",
    "se-FI": "D.M.YYYY",
    "se-NO": "DD.MM.YYYY",
    "se-SE": "YYYY-MM-DD",
    "si-LK": "YYYY-MM-DD",
    "sk-SK": "D. M. YYYY",
    "sl-SI": "D.M.YYYY",
    "sma-NO": "DD.MM.YYYY",
    "sma-SE": "YYYY-MM-DD",
    "smj-NO": "DD.MM.YYYY",
    "smj-SE": "YYYY-MM-DD",
    "smn-FI": "D.M.YYYY",
    "sms-FI": "D.M.YYYY",
    "sq-AL": "YYYY-MM-DD",
    "sr-Cyrl-BA": "D.M.YYYY",
    "sr-Cyrl-CS": "D.M.YYYY",
    "sr-Cyrl-ME": "D.M.YYYY",
    "sr-Cyrl-RS": "D.M.YYYY",
    "sr-Latn-BA": "D.M.YYYY",
    "sr-Latn-CS": "D.M.YYYY",
    "sr-Latn-ME": "D.M.YYYY",
    "sr-Latn-RS": "D.M.YYYY",
    "sv-FI": "D.M.YYYY",
    "sv-SE": "YYYY-MM-DD",
    "sw-KE": "M/D/YYYY",
    "syr-SY": "DD/MM/YYYY",
    "ta-IN": "DD-MM-YYYY",
    "te-IN": "DD-MM-yy",
    "tg-Cyrl-TJ": "DD.MM.yy",
    "th-TH": "D/M/YYYY",
    "tk-TM": "DD.MM.yy",
    "tn-ZA": "YYYY/MM/DD",
    "tr-TR": "DD.MM.YYYY",
    "tt-RU": "DD.MM.YYYY",
    "tzm-Latn-DZ": "DD-MM-YYYY",
    "ug-CN": "YYYY-M-D",
    "uk-UA": "DD.MM.YYYY",
    "ur-PK": "DD/MM/YYYY",
    "uz-Cyrl-UZ": "DD.MM.YYYY",
    "uz-Latn-UZ": "DD/MM YYYY",
    "vi-VN": "DD/MM/YYYY",
    "wo-SN": "DD/MM/YYYY",
    "xh-ZA": "YYYY/MM/DD",
    "yo-NG": "D/M/YYYY",
    "zh-CN": "YYYY/M/D",
    "zh-HK": "D/M/YYYY",
    "zh-MO": "D/M/YYYY",
    "zh-SG": "D/M/YYYY",
    "zh-TW": "YYYY/M/D",
    "zu-ZA": "YYYY/MM/DD",
};