import {ref, computed, watch} from "vue";

export {
    defaultSettings,
    createFormErrors,
}

const defaultSettings = {
    translationOrder: ['common.errorList'],
    customHiddenErrorTypeName: 'hiddenReactive',
    customErrorTypeName: 'customReactive',
    excludeErrorKeys: [],
    hideErrorOnChange: true,
    hideErrorAlias: [], //{whenFieldName: fieldName, hideFieldName: fieldName}
    requiredFields: [],
    requiredError: 'required',
    requiredErrorTypeName: 'requiredReactive',
}

function _typedErrors(type, errors) {
    Object.keys(errors).forEach(function(key) {
        let message = errors[key];
        let messageParams = {};
        let onlyCustomError = false;
        if(typeof errors[key] === "object"){
            message = errors[key].code;
            messageParams = errors[key].params;
            onlyCustomError = !!errors[key].onlyCustomError; //remove the error if the error disappears from customErrors 
        }
        errors[key] = {errorType: type, message, messageParams, onlyCustomError};
    });
    return errors;
}

/*
* formObjects = [mainDataObject, otherWatchedObjects ... ]
*
* mainDataObject - when field in object changed it will remove visible error with the same field name (field in errorObj)
*
* customAddErrorsCallback return object {fieldName: messageCode, ... or fieldName: {code: messageCode, params: {} } }
* */

function createFormErrors(t, te, formObjects, customAddErrorsCallback = null, settings = defaultSettings) {
    const mainDataCast = {};
    const hiddenErrorObj = ref({}); // { fieldName: {message: , errorType: }}
    const errorObj = ref({}); // { fieldName: {message: , errorType: }}
    settings = {...defaultSettings, ...settings};
    if(!Array.isArray(formObjects)) formObjects = [formObjects];

    watch(formObjects, (newValues) => {
        const mainData = newValues[0];

        if(settings.hideErrorOnChange){
            Object.keys(mainData).forEach(fieldName => {
                const fieldValue = JSON.stringify(mainData[fieldName]);
                if(mainDataCast[fieldName] !== fieldValue){
                    const aliases = settings.hideErrorAlias.filter(a => a.whenFieldName === fieldName);
                    aliases.forEach(alias => {
                        delete errorObj.value[alias.hideFieldName];
                    })
                    delete errorObj.value[fieldName];
                }
                mainDataCast[fieldName] = fieldValue;
            })
        }

        const requiredErrors = {};
        settings.requiredFields.forEach(fieldName => {
            if(!mainData[fieldName]) requiredErrors[fieldName] = settings.requiredError;
        })
        removeHiddenErrors(settings.requiredErrorTypeName);
        _typedErrors(settings.requiredErrorTypeName, requiredErrors);

        let customErrors = {};
        if(customAddErrorsCallback) {
            customErrors = customAddErrorsCallback();
            removeHiddenErrors(settings.customHiddenErrorTypeName);
            _typedErrors(settings.customHiddenErrorTypeName, customErrors);
            if(settings.hideErrorOnChange){
                Object.keys(errorObj.value).forEach(fieldName => {
                    if(errorObj.value[fieldName].onlyCustomError && !customErrors[fieldName]) delete errorObj.value[fieldName];
                })
            }
        }

        hiddenErrorObj.value = Object.assign({}, hiddenErrorObj.value, customErrors, requiredErrors);
    }, {immediate: true, deep: true});


    function addErrors(errors, errorType = 'request') { // errors: {fieldName: messageCode, ...}
        _typedErrors(errorType, errors);
        errorObj.value = Object.assign({}, errorObj.value, errors);
        return null;
    }
    function addCatchErrors(err, errorType = 'request') { // err: { "success": false, "errorCode": "invalid_parameter", "error": "Invalid Parameter", "fieldErrors": { "expirationDate": "invalid_value" }    }
        if(err.fieldErrors) return addErrors(err.fieldErrors, errorType);
        let message = t('common.errorList.sorrySomethingWrong');
        if (err.errorCode) {
            const _message = settings.translationOrder.map(g => `${g}.${err.errorCode}`).find(te);
            message = _message ? t(_message) : message;
        }
        return message;
    }

    function removeErrors(errorType = 'request') {
        const newObj = {};
        Object.keys(errorObj.value).forEach(function(key) {
            if(errorObj.value[key].errorType !== errorType) newObj[key] = errorObj.value[key];
        });
        errorObj.value = newObj;
    }
    function removeHiddenErrors(errorType = 'request') {
        const newObj = {};
        Object.keys(hiddenErrorObj.value).forEach(function(key, index) {
            if(hiddenErrorObj.value[key].errorType !== errorType) newObj[key] = hiddenErrorObj.value[key];
        });
        hiddenErrorObj.value = newObj;
    }

    function showHiddenErrors() { // return true if there are errors
        if(Object.keys(hiddenErrorObj.value).filter(i => !settings.excludeErrorKeys.includes(i)).length === 0) return false;
        errorObj.value = Object.assign({}, hiddenErrorObj.value);
        return true;
    }

    function hasErrors() {
        return Object.keys(errorObj.value).filter(i => !settings.excludeErrorKeys.includes(i)).length > 0;
    }

    return {
        errors: computed(() => {
            const viewErrors = {};
            Object.keys(errorObj.value).forEach(function(key, index) {
                const messageCode = errorObj.value[key].message;
                const messageParams = errorObj.value[key].messageParams;
                const foundTranslation = settings.translationOrder.map(g => `${g}.${messageCode}`).find(te);
                viewErrors[key] = foundTranslation ? t(foundTranslation, messageParams) : messageCode;
            });
            return viewErrors;
        }),
        addErrors,
        addCatchErrors,
        removeErrors,
        showHiddenErrors,
        hasErrors: computed(hasErrors),
    };
}
