<template>
  <div>
    <Breadcrumbs :items="breadcrumbData"/>

    <CustomerSupportCaseManage
        v-if="csSupportRequestExternalId"
        :is-admin="isAdmin"
        :csSupportRequestExternalId="csSupportRequestExternalId"
        :ticket="ticketInfo"
    />

  </div>
</template>

<script setup>
import Breadcrumbs from "Common/components/controls/breadcrumbs";
import CustomerSupportCaseManage from "./components/CustomerSupportCaseManage";
import {useCustomerSupportStore} from "Common/stores/customerSupportStore";
import {computed, onMounted, ref, watch} from "vue";
import {useI18n} from "vue-i18n";
import {useRouter} from "vue-router";
import {useUserStore} from "Common/stores/userStore";

const router = useRouter();


const customerSupportStore = useCustomerSupportStore();
const {t} = useI18n({useScope: 'global'});
const ticketInfo = ref(null)

const breadcrumbData = computed(() => [{
  "name": t('common.customerSupport'),
  "link": "/customer-support",
  "active": false
}, {
  "name": t('common.case'),
  "link": "#",
  "active": true
}]);

watch(router.currentRoute, (newRoute, oldRoute) => {
  if (newRoute.params.csSupportRequestExternalId && newRoute.params.csSupportRequestExternalId !== oldRoute?.params?.csSupportRequestExternalId) {
    customerSupportStore.loadCustomerSupportCaseById(newRoute.params.csSupportRequestExternalId).then((data) => {
      breadcrumbData.value[1].name = t('common.case') + ' ' + data.caseId;
      ticketInfo.value = data
    }).catch((err) => {
      console.log(err);
    })
  }
}, {
  immediate: true,
});

const csSupportRequestExternalId = computed(() => router.currentRoute.value?.params?.csSupportRequestExternalId);

const userStore = useUserStore();

const isAdmin = computed(() => !!userStore.user.permissions.cs_manage_write);

onMounted(() => {
  customerSupportStore.getCustomerSupportStats()
})

</script>
