import { defineStore } from 'pinia';
import axios from "axios";
import { environment } from 'Common/environments/environment';
import {PaymentSystemType, CardExternalId, ConvertPaymentSystemType} from 'Common/types/payment_type';
import { useOrganizationStore } from './organizationStore';
import { useUserStore } from './userStore';

function defineBillingStore(id)  {
    return defineStore(id, {
        state: () => {
            return {
                transactionTypes: [],
                cards: [],
                balances: {},
                settings: {}, //{meta:{}, settings:{}}

                paymentSystemType: PaymentSystemType.cardcom
            }
        },
        getters: {
            paymentSystem: (state) => {
                return ConvertPaymentSystemType(state.settings?.settings?.paymentGatewayType);
            },
            hasCards: (state) => {
                return state.cards.length > 0
            },
            orgCurrentBalance: (state) => {
                const os = useOrganizationStore()
                return state.balances[os.userOrg.organizationExternalId]?.currentBalance || 0
            },
            currentBalance: (state) => {
                const os = useOrganizationStore()
                return state.balances[os.organization.organizationExternalId]?.currentBalance || 0
            },
            currentMonthlyBalance: (state) => {
                const os = useOrganizationStore()
                return state.balances[os.organization.organizationExternalId]?.currentMonthlyBalance || 0
            },
            rawBalance: (state) => {
                const os = useOrganizationStore()
                return state.balances[os.organization.organizationExternalId]?.rawBalance || 0
            }
        },
        actions: {
            getToken(){
                return axios.post(environment.billingServiceUrl + '/payment/getToken', {})
                    .then((response) => {
                        if(!response.data.success) return Promise.reject(response.data);
                        return response.data.data || "";
                    });
            },
            getRedirectUrl(data){
                return axios.post(environment.billingServiceUrl + '/payment/getRedirectUrl', {data})
                    .then((response) => {
                        if(!response.data.success) return Promise.reject(response.data);
                        return response.data.data || "";
                    });
            },
            getTransactionTypes(){
                return axios.post(environment.billingServiceUrl + '/management/getTransactionTypes', {})
                    .then((response) => {
                        if(!response.data.success) return Promise.reject(response.data);
                        this.transactionTypes = response.data.data || []
                        return [...this.transactionTypes]
                    });
            },
            getOrgStats(){
                return axios.post(environment.billingServiceUrl + '/management/getOrgStats', {})
                    .then((response) => {
                        if(!response.data.success) return Promise.reject(response.data);
                        return response.data.data;
                    });
            },
            getPaymentsReport(data){
                return axios.post(environment.billingServiceUrl + '/management/getPaymentsReport', {data})
                    .then((response) => {
                        if(!response.data.success) return Promise.reject(response.data);
                        return response.data.data;
                    });
            },

            downloadAttachment(data, download = true) {
                return axios.post(`${environment.billingServiceUrl}/management/downloadDocument`, {
                    data
                }, {
                    responseType: 'arraybuffer' // request to get data as binary
                }).then(r => {

                    const contentDisposition = r?.headers['content-disposition'];
                    const contentType = r?.headers['content-type'];
                    const fileName = (contentDisposition?.match(/filename="?([^"]*)/i) || []) [1];

                    // create file link in browser's memory
                    const blob = new Blob([r.data], {type: contentType});
                    const href = URL.createObjectURL(blob);

                    if (!download) {
                        return href;
                    }

                    // create "a" HTML element with href to file & click
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', fileName || 'unknown');
                    document.body.appendChild(link);
                    link.click();

                    // clean up "a" element & remove ObjectURL
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                });
            },

            addOrganizationBalance(data){
                return axios.post(environment.billingServiceUrl + '/management/addOrganizationBalance', {data: data})
                    .then((response) => {
                        if(!response.data.success) return Promise.reject(response.data);
                        return response.data.data || "";
                    });
            },
            revokeCredits(data){
                return axios.post(environment.billingServiceUrl + '/management/revokeCredits', data, {headers: { "Content-Type": "multipart/form-data" },})
                    .then((response) => {
                        if(!response.data.success) return Promise.reject(response.data);
                        return response.data.data || "";
                    });
            },
            addManualPayment(data){
                return axios.post(environment.billingServiceUrl + '/management/addManualPayment', {data: data})
                    .then((response) => {
                        if(!response.data.success) return Promise.reject(response.data);
                        return response.data.data || "";
                    });
            },

            pay(data){
                return axios.post(environment.billingServiceUrl + '/payment/pay', {data: data})
                    .then((response) => {
                        if(!response.data.success) return Promise.reject(response.data);
                        return response.data.data || "";
                    });
            },
            async addCard(data) {
                return await axios.post(environment.billingServiceUrl + '/payment/addCard', {data: data}).then(async (response) => {
                    if(!response.data.success) return Promise.reject(response.data);

                    try {
                        const userStore = useUserStore();
                        await userStore.loadUser(true)
                    } catch (e) {
                        console.log(e)
                    }

                    return response.data.data || {};
                });
            },
            setDefaultCard(cardExternalId) {
                const externalId = CardExternalId[this.paymentSystem];

                return axios.post(environment.billingServiceUrl + '/payment/setDefaultCard',{
                    data: {
                        [externalId]: cardExternalId
                    }
                }).then((response) => {
                    if(!response.data.success) return Promise.reject(response.data);
                    this.cards.forEach(c => c.isDefault = c[externalId] === cardExternalId);
                    this.cards = this.cards.sort((a, b) => b.isDefault - a.isDefault);
                });
            },
            async removeCard(cardExternalId) {
                const externalId = CardExternalId[this.paymentSystem];

                return await axios.post(environment.billingServiceUrl + '/payment/delete', {
                    data: {
                        [externalId]: cardExternalId
                    }
                }).then(async (response) => {
                    if(!response.data.success) return Promise.reject(response.data);
                    this.cards = this.cards.filter(c => c[externalId] !== cardExternalId);

                    try {
                        const userStore = useUserStore();
                        await userStore.loadUser(true)
                    } catch (e) {
                        console.log(e)
                    }

                    return response.data.data || {};
                });
            },
            getCards() {
                return axios.post(environment.billingServiceUrl + '/payment/getCards').then((response) => {
                    if(!response.data.success) return Promise.reject(response.data);
                    const data = response.data.data || []
                    this.cards = data.sort((a, b) => b.isDefault - a.isDefault);
                    return this.cards;
                });
            },
            getInvoices(organizationExternalId, filter, params) {
                return axios.post(environment.billingServiceUrl + '/statistic/invoices', {
                    data: {
                        filter: {...filter, organizationExternalId: organizationExternalId},
                        params: params
                    }
                }).then(response => {
                    if(!response.data.success)
                        throw new Error(response.data.error || "Failed to get invoices info.");
                    return (response.data.data || []);
                });
            },
            getInvoicesByMonth(organizationExternalId) {
                return axios.post(environment.billingServiceUrl + '/statistic/invoices-by-months', {
                    data: {
                        filter: {organizationExternalId: organizationExternalId}
                    }
                }).then(response => {
                    if(!response.data.success)
                        throw new Error(response.data.error || "Failed to get invoices info.");
                    return (response.data.data || []);
                });
            },
            getChargeByAppType(organizationExternalId, filter) {
                return axios.post(environment.billingServiceUrl + '/statistic/charge-by-app-type', {
                    data: {
                        filter: {
                            organizationExternalId: organizationExternalId,
                            ...filter
                        }
                    }
                }).then(response => {
                    if(!response.data.success)
                        throw new Error(response.data.error || "Failed to get invoices info.");
                    return (response.data.data || []);
                });
            },
            getBalance(userOrg = false, includeDebtLimit = false) {
                const that = this;
                return axios.post(environment.billingServiceUrl + '/statistic/getBalance').then(response => {
                    if (!response.data.success) return Promise.reject(response.data);

                    const os = useOrganizationStore()

                    const id = userOrg ? os.userOrgExternalId : os.organization.organizationExternalId;

                    that.balances[id] = {
                        ...that.balances[id],
                        rawBalance: (response.data.data.rawBalance || 0),
                        currentBalance: (response.data.data.balance || 0),
                        debtLimit: (response.data.data.debtLimit || 0),
                        phoneNumberAlreadyGotGift: (response.data.data.phoneNumberAlreadyGotGift || false),
                    }
                    return that.balances[id].currentBalance - (includeDebtLimit ? that.balances[id].debtLimit : 0)
                });
            },
            getManualPaymentTypes() {
                return axios.post(environment.billingServiceUrl + '/manualPaymentTypes/search').then(response => {
                    if (!response.data.success) return Promise.reject(response.data);
                    return response.data?.data?.items || []
                });
            },
            getManualPaymentType(manualPaymentTypeExternalId) {
                return axios.post(
                    environment.billingServiceUrl + '/manualPaymentTypes/getByExternalId',
                    {data: {manualPaymentTypeExternalId}}
                ).then(response => {
                    if (!response.data.success) return Promise.reject(response.data);
                    return response.data?.data || {}
                });
            },
            insertManualPaymentType(data) {
                return axios.post(
                    environment.billingServiceUrl + '/manualPaymentTypes/insert',
                    {data}
                ).then(response => {
                    if (!response.data.success) return Promise.reject(response.data);
                    return response.data?.data || {}
                });
            },
            updateManualPaymentType(data) {
                return axios.post(
                    environment.billingServiceUrl + '/manualPaymentTypes/update',
                    {data}
                ).then(response => {
                    if (!response.data.success) return Promise.reject(response.data);
                    return response.data?.data || {}
                });
            },
            getMonthlyBalance(userOrg = false) {
                const that = this;
                return axios.post(environment.billingServiceUrl + '/statistic/getMonthlyBalance').then(response => {
                    if (!response.data.success) return Promise.reject(response.data);

                    const os = useOrganizationStore()

                    const id = userOrg ? os.userOrgExternalId : os.organization.organizationExternalId;

                    that.balances[id] = {
                        ...that.balances[id],
                        currentMonthlyBalance: (response.data.data.amount || 0),
                    }
                    return that.balances[id].currentMonthlyBalance
                });
            },
            downloadInvoice(filter) {
                return axios.post(environment.billingServiceUrl + '/statistic/downloadInvoiceReport', {
                    data: {
                        filter
                    }
                }).then(response => {
                    if(!response.data.success)
                        throw new Error(response.data.error || "Failed to get invoices report.");

                    return (response.data.data || []);
                });
            },
            getBillingSettings() {
                return axios.post(environment.billingServiceUrl + '/settings/getSettings').then(async response => {
                    if(!response.data.success)
                        throw new Error(response.data.error || "Failed to get invoices report.");
                    const data = (response.data.data || {});

                    if(data?.settings?.paymentGatewayType !== this.settings?.settings?.paymentGatewayType){
                        await this.getCards();
                    }

                    return this.settings = data;
                });
            },
            updateSettings(data) {
                return axios.post(environment.billingServiceUrl + '/settings/update', {data}).then(response => {
                    if (!response.data.success) return Promise.reject(response.data);

                    return this.getBillingSettings();
                });
            },
            addProformaInvoice(data) {
                return axios.post(environment.billingServiceUrl + '/proformaInvoice/addProformaInvoice', {data}).then(response => {
                    if (!response.data.success) return Promise.reject(response.data);

                    return response.data.data;
                });
            },
            getProformaInvoiceTemplate() {
                return axios.post(environment.billingServiceUrl + '/proformaInvoice/getProformaInvoiceTemplate').then(response => {
                    if (!response.data.success) return Promise.reject(response.data);

                    return response.data.data;
                });
            },
            saveProformaInvoiceTemplate(data) {
                const formData = Object.keys(data).reduce((f, key) => {
                    if(data[key] instanceof File) f.append(key, data[key]);
                    else f.append(key, JSON.stringify(data[key]));
                    return f;
                }, new FormData())
                
                return axios.post(environment.billingServiceUrl + '/proformaInvoice/saveProformaInvoiceTemplate', formData, {headers: { "Content-Type": "multipart/form-data" }}).then(response => {
                    if (!response.data.success) return Promise.reject(response.data);

                    return response.data.data;
                });
            },
            getProformaInvoiceSignatureUrl() {
                return environment.billingServiceUrl + '/proformaInvoice/getProformaInvoiceSignature';
            },
            downloadProformaInvoice(data) {
                return axios.post(environment.billingServiceUrl + '/proformaInvoice/downloadProformaInvoice', {data}, {responseType: "blob"}).then(response => {
                    const data = response?.data;
                    if(data.success === false) console.log(data);
                    if (data) {
                        const blob = new Blob([data]);
                        const url = window.URL.createObjectURL(blob);
                        console.log(url)
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = 'Proforma Invoice.pdf';
                        a.click();
                    }
                });
            },
        }
    });
}
const useBillingStore = defineBillingStore('billingStore');

export {
    useBillingStore,
    defineBillingStore,
}
