import { defineStore } from 'pinia';
import {ROUTES} from "@/utility/routeUtils";
import {checkIfDeviceIsMobile} from "@/utility/browserUtils";
import {disableDocumentScroll, enableDocumentScroll} from "Common/utility/modalUtility";

const welcomeAnalyzeNowModalsKeys = {
    feelGPT: 'dsma-feel-gpt',
    diamond: 'dsma-diamond',
    discovery: 'dsma-discovery-report',
}

const analyzeNowRootRoutes = [
    ROUTES.AnalyzeNowRoot,
    ROUTES.FeelGPTRoot,
    ROUTES.EmotionalDiamondRoot,
    ROUTES.DiscoveryReportRoot,
    ROUTES.ApptoneQuestionnairesRoot,
    ROUTES.AppAnalyzeFile
]

export const useAppStore = defineStore('appStore', {
    state: () => {
        return {
            isMobile: checkIfDeviceIsMobile(),
            hamburger:{
                sidebar:{
                    isOpened: !checkIfDeviceIsMobile()
                }
            },
            welcomeAnalyzeNowModals:{
                feelGPT: {
                    checkbox: localStorage.getItem(welcomeAnalyzeNowModalsKeys.feelGPT) === '1',
                    showIfUnchecked: true,
                },
                diamond: {
                    checkbox: localStorage.getItem(welcomeAnalyzeNowModalsKeys.diamond) === '1',
                    showIfUnchecked: true,
                },
                discovery: {
                    checkbox: localStorage.getItem(welcomeAnalyzeNowModalsKeys.discovery) === '1',
                    showIfUnchecked: true,
                }
            },
            modals:{
                openedModalIndexes: [],
            },
            showAutofillPlaceholder: false,
            distributorModals: {
                actionRequired: false,
                agreement: false,
                completeSetup: false,
                bankDetailsReset: false,
            },
            proformaInvoiceModal: {
                show: false,
            },
        }
    },
    getters: {
        feelGPTWelcomeModal: (state) => {
            return !state.welcomeAnalyzeNowModals.feelGPT.checkbox && state.welcomeAnalyzeNowModals.feelGPT.showIfUnchecked;
        },
        diamondWelcomeModal: (state) => {
            return !state.welcomeAnalyzeNowModals.diamond.checkbox && state.welcomeAnalyzeNowModals.diamond.showIfUnchecked;
        },
        discoveryWelcomeModal: (state) => {
            return !state.welcomeAnalyzeNowModals.discovery.checkbox && state.welcomeAnalyzeNowModals.discovery.showIfUnchecked;
        },
    },
    actions: {
        checkWelcomeAnalyzeNowModals(to, from) {
            const to_AnalyzeNowApp = to.matched.length > 1 && analyzeNowRootRoutes.includes(to.matched[1].name);
            const from_AnalyzeNowApp = from.matched.length > 1 && analyzeNowRootRoutes.includes(from.matched[1].name);
            
            if(!to_AnalyzeNowApp || !from_AnalyzeNowApp){
                Object.keys(this.welcomeAnalyzeNowModals).forEach((key) => {
                    this.welcomeAnalyzeNowModals[key].showIfUnchecked = true;  
                })
            }
        },
        closeWelcomeAnalyzeNowModal(modalName) {
            localStorage.setItem(welcomeAnalyzeNowModalsKeys[modalName], this.welcomeAnalyzeNowModals[modalName]?.checkbox ? '1' : '0');
            this.welcomeAnalyzeNowModals[modalName].showIfUnchecked = false;
        },
        onNewModalOpened(){
            const newIndex = Math.max(...this.modals.openedModalIndexes, 0) + 1;
            this.modals.openedModalIndexes.push(newIndex);
            disableDocumentScroll();
            return newIndex;
        },
        onModalClosed(index){
            this.modals.openedModalIndexes = this.modals.openedModalIndexes.filter(i => i !== index);
            if(this.modals.openedModalIndexes.length === 0){
                enableDocumentScroll();
            }
        }
    }
})
